<template>
  <v-app>
    <v-snackbar v-model="snackbar" :timeout="8000" top color="black" dark  > {{text}}
      <v-btn color="white" text @click="snackbar = false"> Cerrar </v-btn>
    </v-snackbar>

    <v-app-bar
      color="white"
      class="elevation-0"
      app
    >

      <v-toolbar-title>
        <v-img  
          src="@/assets/logo.png" width="180">
        </v-img>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-tabs
        color="#042737"
        right
      >
        <v-tab>HOME</v-tab>
        <v-tab>SERVICIOS</v-tab>
        <v-tab>PAQUETES</v-tab>
        <v-tab>MÁS</v-tab>
      </v-tabs>
    </v-app-bar>

    <v-row class="pb-0">
      <v-col cols="12" class="pb-0">
        <v-card style="border-radius: 0px;">
          <v-img 
            align="center"
            class="white--text align-center"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
            src="@/assets/light.jpg">
            <div class="text-h2">BIENVENIDO A SOFSOLUTION</div><br/>
            <div>¡Tu pones la idea, nosotros la desarrollamos!</div><br/>
            <v-btn 
              dark
              style="border-radius: 0px;"
              color="#005E89" 
              href="https://api.whatsapp.com/send?phone=528126137716&text=Hola, me interesa saber un poco más sobre tus servicios"
              target="_blank"
              x-large>CONTÁCTANOS</v-btn>
          </v-img>
        </v-card>
      </v-col>
    </v-row>


    <v-row class="py-10">
      <v-col cols="12" class="pt-10">
        <v-card style="border-radius: 0px" class="elevation-0">
          <v-card-title class="headline text-center" >
            <v-spacer></v-spacer>
            <div class="text-xs-h6 text-md-h4">SERVICIOS</div>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card class="transparent pt-4 elevation-0" >
            <v-row align="center" justify="center">
              <v-card height="3" width="100" style="background:#050B1F;">
              </v-card>
            </v-row>
          </v-card>
          </v-card-title>
          <v-card-text class="pt-10">
            <v-row justify="space-around">

              <v-col cols="12" md="6" lg="5">
                <v-card class="elevation-0">
                  <div class="d-flex flex-no-wrap ">
                    <v-avatar
                      class="ma-3"
                      size="125"
                      tile
                    >
                      <v-icon size="80" color="#042737">mdi-store-outline</v-icon>
                      <!-- <v-img src="@/assets/imagenes/tiendaOnline.jpg"></v-img> -->
                    </v-avatar>
                    <div>
                      <v-card-title
                        class="headline"
                      >Tienda en línea</v-card-title>

                      <v-card-subtitle >
                        No te quedes atrás, es hora de modernizarte y generar más ingresos, contrata ahora nuestro paquete de tienda en línea. Empieza a generar más ingresos, más clientes, lleva tu negocio más allá y convierte tu negocio en algo digital
                      </v-card-subtitle>

                      <v-card-actions>
                        <v-card 
                          href="https://api.whatsapp.com/send?phone=528126137716&text=Hola, me interesa saber un poco más sobre tus servicios"
                          class="elevation-0"
                          target="_blank"
                          >
                          <div style="color: #042737;">Más información ➤</div>
                        </v-card>
                      </v-card-actions>
                    </div>

                  </div>
                </v-card>
              </v-col>

              <v-col cols="12" md="6" lg="5">
                <v-card class="elevation-0">
                  <div class="d-flex flex-no-wrap ">
                    <v-avatar
                      class="ma-3"
                      size="125"
                      tile
                    >
                      <v-icon size="80" color="#042737">mdi-cellphone-iphone</v-icon>
                    </v-avatar>
                    <div>
                      <v-card-title
                        class="headline"
                      >App's móviles</v-card-title>

                      <v-card-subtitle >
                        Lleva tu negocio en la palma de tu mano, ahora con nuestras app's móviles podrás llevarlo a donde sea que estés, administrar tu negocio nunca había sido tan fácil, ahorra tiempo y dinero, convertimos tu negocio en una aplicación móvil
                      </v-card-subtitle>

                      <v-card-actions>
                        <v-card 
                          href="https://api.whatsapp.com/send?phone=528126137716&text=Hola, me interesa saber un poco más sobre tus servicios"
                          class="elevation-0"
                          target="_blank"
                          >
                          <div style="color: #042737;">Más información ➤</div>
                        </v-card>
                      </v-card-actions>
                    </div>

                  </div>
                </v-card>
              </v-col>

              <v-col cols="12" md="6" lg="5" class="pt-10">
                <v-card class="elevation-0">
                  <div class="d-flex flex-no-wrap ">
                    <v-avatar
                      class="ma-3"
                      size="125"
                      tile
                    >
                      <v-icon size="80" color="#042737">mdi-laptop-chromebook</v-icon>
                    </v-avatar>
                    <div>
                      <v-card-title
                        class="headline"
                      >Landing page</v-card-title>

                      <v-card-subtitle >
                        Convertimos a tu empresa en una página web, deja que las personas te conozcan y sepan de ti, ponemos toda tu información en una página web para que tus clientes sepan más sobre ti y tus servicios
                      </v-card-subtitle>

                      <v-card-actions>
                        <v-card 
                          href="https://api.whatsapp.com/send?phone=528126137716&text=Hola, me interesa saber un poco más sobre tus servicios"
                          class="elevation-0"
                          target="_blank"
                          >
                          <div style="color: #042737;">Más información ➤</div>
                        </v-card>
                      </v-card-actions>
                    </div>

                  </div>
                </v-card>
              </v-col>

              <v-col cols="12" md="6" lg="5" class="pt-10">
                <v-card class="elevation-0">
                  <div class="d-flex flex-no-wrap ">
                    <v-avatar
                      class="ma-3"
                      size="125"
                      tile
                    >
                      <v-icon size="80" color="#042737">mdi-file-multiple-outline</v-icon>
                    </v-avatar>
                    <div>
                      <v-card-title
                        class="headline"
                      >Catálogo/Menú de artículos</v-card-title>

                      <v-card-subtitle >
                        ¿Te gustaría que más personas conocieran tus productos?, !Nosotros te ayudamos¡ digitalizamos tu negocio en una página web para que más clientes te conozcan.
                      </v-card-subtitle>

                      <v-card-actions>
                        <v-card 
                          href="https://api.whatsapp.com/send?phone=528126137716&text=Hola, me interesa saber un poco más sobre tus servicios"
                          class="elevation-0"
                          target="_blank"
                          >
                          <div style="color: #042737;">Más información ➤</div>
                        </v-card>
                      </v-card-actions>
                    </div>

                  </div>
                </v-card>
              </v-col>

              <v-col cols="12" md="6" lg="5" class="pt-10">
                <v-card class="elevation-0">
                  <div class="d-flex flex-no-wrap ">
                    <v-avatar
                      class="ma-3"
                      size="125"
                      tile
                    >
                      <v-icon size="80" color="#042737">mdi-file-document-outline</v-icon>
                    </v-avatar>
                    <div>
                      <v-card-title
                        class="headline"
                      >Cotizador web</v-card-title>

                      <v-card-subtitle >
                        Cotiza hasta un 80% más rápido con nuestro sistema cotizador, que te ayuda a realizar y enviar cotizaciones a tus clientes de manera más fácil y rápida
                      </v-card-subtitle>

                      <v-card-actions>
                        <v-card 
                          href="https://api.whatsapp.com/send?phone=528126137716&text=Hola, me interesa saber un poco más sobre tus servicios"
                          class="elevation-0"
                          target="_blank"
                          >
                          <div style="color: #042737;">Más información ➤</div>
                        </v-card>
                      </v-card-actions>
                    </div>

                  </div>
                </v-card>
              </v-col>

              <v-col cols="12" md="6" lg="5" class="pt-10">
                <v-card class="elevation-0">
                  <div class="d-flex flex-no-wrap ">
                    <v-avatar
                      class="ma-3"
                      size="125"
                      tile
                    >
                      <v-icon size="80" color="#042737">mdi-file-image-outline</v-icon>
                    </v-avatar>
                    <div>
                      <v-card-title
                        class="headline"
                      >Diseño</v-card-title>

                      <v-card-subtitle >
                        Diseñamos tu logo e imagenes publicitarias para tus redes sociales
                      </v-card-subtitle>

                      <v-card-actions>
                        <v-card 
                          href="https://api.whatsapp.com/send?phone=528126137716&text=Hola, me interesa saber un poco más sobre tus servicios"
                          class="elevation-0"
                          target="_blank"
                          >
                          <div style="color: #042737;">Más información ➤</div>
                        </v-card>
                      </v-card-actions>
                    </div>

                  </div>
                </v-card>
              </v-col>

            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Servicios -->
    <v-row class="pt-10" style="background: #050B1F;">
      <v-col cols="12" class="pt-10">
        <v-card style="border-radius: 0px;" class="elevation-0 transparent" dark>

          <v-card-text class="pt-10">
            <v-row justify="space-around">
              
              <v-col cols="12" md="4" lg="2">
                <v-card class="elevation-0 transparent text-center" align="center">
                    <v-icon size="50" color="#007AEC">mdi-keyboard-outline</v-icon>
                  <v-card-title class="headline text-center" >
                    <v-spacer></v-spacer>
                    <div>DISEÑO DE MODA</div>
                    <v-spacer></v-spacer>
                  </v-card-title>
                  <v-card class="transparent pt-4 elevation-0" >
                    <v-row align="center" justify="center">
                      <v-card height="4" width="20%" style="background:#007AEC;">
                      </v-card>
                    </v-row>
                  </v-card>

                  <v-card-subtitle class="pt-10">
                    Contamos con las mejores herramientas para diseñar todo tipo de páginas, adaptables a cualquier dispositivo.
                  </v-card-subtitle>

                  <v-card-actions>
                    <v-card 
                      href="https://api.whatsapp.com/send?phone=528126137716&text=Hola, me interesa saber un poco más sobre tus servicios"
                      class="elevation-0"
                      target="_blank"
                      >
                    </v-card>
                  </v-card-actions>
                </v-card>
              </v-col>

              <v-col cols="12" md="4" lg="2">
                <v-card class="elevation-0 transparent text-center" align="center">
                    <v-icon size="50" color="#007AEC">mdi-camera-outline</v-icon>
                  <v-card-title class="headline text-center" >
                    <v-spacer></v-spacer>
                    <div>FOTOGRAFÍA</div>
                    <v-spacer></v-spacer>
                  </v-card-title>
                  <v-card class="transparent pt-4 elevation-0" >
                    <v-row align="center" justify="center">
                      <v-card height="4" width="20%" style="background:#007AEC;">
                      </v-card>
                    </v-row>
                  </v-card>

                  <v-card-subtitle class="pt-10">
                    Diseñados tus imágenes publicitarias para tus redes sociales, contamos con un servicio de diseño para todas tus necesidades
                  </v-card-subtitle>

                  <v-card-actions>
                    <v-card 
                      href="https://api.whatsapp.com/send?phone=528126137716&text=Hola, me interesa saber un poco más sobre tus servicios"
                      class="elevation-0"
                      target="_blank"
                      >
                    </v-card>
                  </v-card-actions>
                </v-card>
              </v-col>

              <v-col cols="12" md="4" lg="2">
                <v-card class="elevation-0 transparent text-center" align="center">
                    <v-icon size="50" color="#007AEC">mdi-headset</v-icon>
                  <v-card-title class="headline text-center" >
                    <v-spacer></v-spacer>
                    <div>SOPORTE 24/7</div>
                    <v-spacer></v-spacer>
                  </v-card-title>
                  <v-card class="transparent pt-4 elevation-0" >
                    <v-row align="center" justify="center">
                      <v-card height="4" width="20%" style="background:#007AEC;">
                      </v-card>
                    </v-row>
                  </v-card>

                  <v-card-subtitle class="pt-10">
                    Contamos con un servicio disponible las 24 horas, todo para brindarte un mejor servicio
                  </v-card-subtitle>

                  <v-card-actions>
                    <v-card 
                      href="https://api.whatsapp.com/send?phone=528126137716&text=Hola, me interesa saber un poco más sobre tus servicios"
                      class="elevation-0"
                      target="_blank"
                      >
                    </v-card>
                  </v-card-actions>
                </v-card>
              </v-col>

              <v-col cols="12" md="4" lg="2">
                <v-card class="elevation-0 transparent text-center" align="center">
                    <v-icon size="50" color="#007AEC">mdi-facebook</v-icon>
                  <v-card-title class="headline text-center" >
                    <v-spacer></v-spacer>
                    <div>REDES SOCIALES</div>
                    <v-spacer></v-spacer>
                  </v-card-title>
                  <v-card class="transparent pt-4 elevation-0" >
                    <v-row align="center" justify="center">
                      <v-card height="4" width="20%" style="background:#007AEC;">
                      </v-card>
                    </v-row>
                  </v-card>

                  <v-card-subtitle class="pt-10">
                    Te apoyamos creando tus redes sociales, dejando todo preparado para que puedas administrarla
                  </v-card-subtitle>

                  <v-card-actions>
                    <v-card 
                      href="https://api.whatsapp.com/send?phone=528126137716&text=Hola, me interesa saber un poco más sobre tus servicios"
                      class="elevation-0"
                      target="_blank"
                      >
                    </v-card>
                  </v-card-actions>
                </v-card>
              </v-col>

            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- PAQUETES -->
    <v-row justify="space-around">
      <v-col cols="12">
        <v-card-title class="headline text-center" >
          <v-spacer></v-spacer>
          <div class="text-xs-h6 text-md-h4">PAQUETES</div>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card class="transparent pt-4 elevation-0" >
          <v-row align="center" justify="center">
            <v-card height="3" width="100" style="background:#050B1F;">
            </v-card>
          </v-row>
        </v-card>
      </v-col>

      <v-col cols="12" md="4" lg="4">
        <div class="wrapper-card">
          <div class="card">
            <div class="card-title">
              <h3>Manejo de Redes sociales</h3>
              <h4>Facebook e Instagram</h4>
            </div>
            <div class="card-price">
              <h1>
              <sup>$</sup>
              5,000<span style="font-size: 15px;">MXN</span>
              <small>MES</small>
            </h1>
            </div>
            <div class="card-description">
              <ul>
                <li>2 Campañas por semana</li>
                <li>Análisis estadístico</li>
                <li>Conexión a página web</li>
                <li>Diseños personales</li>
                <li>24/7 soporte</li>
                <li></li>
              </ul>
            </div>
            <div class="card-action">
              <button type="button">OBTENER</button>
            </div>
          </div>
        </div>
      </v-col>

      <v-col cols="12" md="4" lg="4">
        <div class="wrapper-card" >
          <div class="card popular">
            <div class="card-ribbon">
              <span>MÁS POPULAR</span>
            </div>
            <div class="card-title">
              <h3>Tienda en línea</h3>
              <h4>Manejo de tus articulos</h4>
            </div>
            <div class="card-price">
              <h1>
              <sup>$</sup>
              500<span style="font-size: 15px;">MXN</span>
              <small>MES</small>
            </h1>
            </div>
            <div class="card-description">
              <ul>
                <li>10GB de espacio</li>
                <li>Artículos ilimitados</li>
                <li>Pagos a través de paypal</li>
                <li>Control y acceso de usuarios</li>
                <li>24/7 soporte</li>
                <li>Envío de promociones</li>
              </ul>
            </div>
            <div class="card-action">
              <button type="button">Get Pro</button>
            </div>
          </div>
        </div>
      </v-col>

      <v-col cols="12"  md="4" lg="4">
        <div class="wrapper-card">
          <div class="card">
            <div class="card-title">
              <h3>Landig page</h3>
              <h4>Página informativa para tu empresa</h4>
            </div>
            <div class="card-price">
              <h1>
              <sup>$</sup>
              349<span style="font-size: 15px;">MXN</span>
              <small>MES</small>
            </h1>
            </div>
            <div class="card-description">
              <ul>
                <li>10GB de espacio</li>
                <li>Dominio personalizado</li>
                <li>Total acceso al proyecto</li>
                <li>24/7 soporte</li>
                <li>Plantilla totalmente gratuita</li>
                <li>Formulario de contacto</li>
              </ul>
            </div>
            <div class="card-action">
              <button type="button">OBTENER</button>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>

    <!-- Personal -->
    <v-row class="pt-10" style="background: #050B1F;">
      <v-col cols="12" class="pt-10">
        <v-card style="border-radius: 0px;" class="elevation-0 transparent" dark>
          <v-card-title class="headline text-center" >
            <v-spacer></v-spacer>
            <div class="text-xs-h6 text-md-h4">EQUIPO</div>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card class="transparent pt-4 elevation-0" >
            <v-row align="center" justify="center">
              <v-card height="3" width="100" style="background:#007AEC;">
              </v-card>
            </v-row>
          </v-card>

          <v-card-text class="pt-10">
            <v-row justify="space-around">
              
              <v-col cols="12" md="4" lg="3">
                <v-card class="elevation-0 transparent text-center" align="center">
                  <v-avatar
                    size="150"
                    color="transparent"
                  >
                    <img src="@/assets/imagenes/eduardo.png" alt="alt">
                  </v-avatar>
                  <v-card-title class="headline text-center" >
                    <v-spacer></v-spacer>
                    <div>Eduardo Hernández</div>
                    <v-spacer></v-spacer>
                  </v-card-title>
                  <v-card class="transparent pt-4 elevation-0" >
                    <v-row align="center" justify="center">
                      <v-card height="4" width="20%" style="background:#007AEC;">
                      </v-card>
                    </v-row>
                  </v-card>

                  <v-card-subtitle class="pt-10">
                    Encargado del área de desarrollo, especializado en el área de front end y lógica de desarrollo, con un amplio conocimiento en el área
                  </v-card-subtitle>

                </v-card>
              </v-col>

              <v-col cols="12" md="4" lg="3">
                <v-card class="elevation-0 transparent text-center" align="center">
                  <v-avatar
                    size="150"
                    color="transparent"
                  >
                    <img src="@/assets/imagenes/edgar.png" alt="alt">
                  </v-avatar>
                  <v-card-title class="headline text-center" >
                    <v-spacer></v-spacer>
                    <div>Edgar Tamez</div>
                    <v-spacer></v-spacer>
                  </v-card-title>
                  <v-card class="transparent pt-4 elevation-0" >
                    <v-row align="center" justify="center">
                      <v-card height="4" width="20%" style="background:#007AEC;">
                      </v-card>
                    </v-row>
                  </v-card>

                  <v-card-subtitle class="pt-10">
                    Encargado del área de desarrollo, especializado en la parte del back end. configuración de servidores y soporte.
                  </v-card-subtitle>

                </v-card>
              </v-col>

            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Satisfaccion -->
    <v-row justify="space-around" class="pb-12 pt-12">
      <v-col cols="12">
        <v-card-title class="headline text-center" >
          <v-spacer></v-spacer>
          <div class="text-xs-h6 text-md-h4">Satisfacción garantizada</div>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card class="transparent pt-4 elevation-0" >
          <v-row align="center" justify="center">
            <v-card height="3" width="100" style="background:#050B1F;">
            </v-card>
          </v-row>
        </v-card>
      </v-col>

      <v-col cols="12" md="6" lg="4">
        <section id="satisfaction">
          <div class="contenedor">
            <br/>
            <br/>
            <div class="contenedor-tarjetas">
              <div class="tarjeta">
                <h4>Clientes satisfechos</h4>
                <br/>
                <p><span style="font-size: 72px;">10 </span><span>clientes</span></p>

                <br/>
                <p><span style="font-size: 72px;">16 </span><span>proyectos</span></p>
              </div>
            </div>
          </div>
        </section>
      </v-col>

      <v-col cols="12" md="6" lg="4">
        <section id="satisfaction">
          <div class="contenedor">
            <br/>
            <br/>
            <div class="tarjeta tarjeta-dos">
            <h4>Me gusta</h4>
            <br/>
            <p><span id="megusta" style="font-size: 72px;"></span><span> Me gusta alcanzados</span></p>
            <button id="likes" class="boton" style="font-size: 18px; border: 0px;" @click="init()">Me gusta 
              {{likes}}
              <v-icon color="white" right>mdi-heart</v-icon>
            </button>
          </div>
          </div>
        </section>
      </v-col>
    </v-row>

   
    <v-row justify="space-around" class="pt-8 pb-12"  style="background: #050B1F;">
      <v-col cols="12" sm="8" md="7" lg="5" xl="4">
        <v-card height="500" class="elevation-0 transparent pb-0 pl-2" style="border-radius: 0px;">
          <div ref="mapajs" id="map" style="height: 100%"></div>
        </v-card>
      </v-col>

      <v-col cols="12"  sm="8" md="7" lg="5" xl="4" class="pa-0 py-0 text-right">
        <v-card flat class="transparent" width="100%" height="100%" id="row">
          <v-card-actions class="px-6" height="100">
            <v-img src="@/assets/imagenes/slogan.png" height="100" contain></v-img>
          </v-card-actions>
          <v-card-title primary-title class="text-center headline font-weight-black">
            <v-spacer></v-spacer>
            <div style="color: white;">CONTÁCTANOS</div> 
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text class="px-6">
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="12" class="">
                  <v-text-field
                    solo
                    v-model="nombre" dense   label="Nombre"  
                    outlined type="text" required hide-details
                    clearable
                    >
                  </v-text-field>
                </v-col>

                <v-col cols="12" class="" sm="6">
                  <v-text-field
                    solo
                    v-model="correo" dense   
                    label="Correo" required outlined hide-details
                    clearable
                    >
                  </v-text-field>
                </v-col>

                <v-col cols="12" class="" sm="6">
                  <v-text-field
                    solo
                    v-model="telefono" dense   hide-details
                    clearable 
                    label="Teléfono" required outlined type="number" 
                    >
                  </v-text-field>
                </v-col>

                <v-col cols="12" class="" >
                  <v-text-field
                    solo
                    dense  autofocus label="Empresa" 
                    required outlined hide-details
                    v-model="empresa"
                    clearable 
                  >
                  </v-text-field>
                </v-col>

                 <v-col cols="12" class="">
                  <v-textarea
                    solo
                    autofocus
                    dense
                    hide-details
                    clearable
                    label="Mensaje"
                    placeholder="Escribe tu mensaje aquí..."
                    rows="3"
                    v-model="mensaje"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="px-6">
            <v-btn block color="#D8A917" dark  @click="enviarCorreo"  class="elevation-0 contenedor">Envíar Información</v-btn>
          </v-card-actions>

          <!-- <v-card-actions class="px-6" height="100">
            <v-img src="@/assets/imagenes/slogan.png" height="100" contain></v-img>
          </v-card-actions> -->
        </v-card>
      </v-col>
    </v-row>

    <v-footer dark style="background:#050B1F; ">
      <v-spacer></v-spacer>
      <v-btn icon class="mr-10" href="https://www.facebook.com/sofsolutiondesarrollo" target="_blank"> <v-icon  large>mdi-facebook</v-icon></v-btn>
      <v-btn icon class="mr-10" href="https://www.instagram.com/sofsolution_software/" target="_blank"><v-icon  large>mdi-instagram</v-icon></v-btn>
      <v-spacer></v-spacer>
    </v-footer>
  </v-app>
</template>

<script>
import HelloWorld from './components/HelloWorld';
import Toolbar    from '@/components/Toolbar';

export default {
  name: 'App',

  components: {
    HelloWorld,
    Toolbar
  },

  data: () => ({
    likes:0,

     nombre:'',
    correo:'',
    telefono:'',
    producto:'',
    cantidad:'',
    empresa:'',
    mensaje:'',

    snackbar: false,
    text: '',

    value:'',

    valid: true,
    rules: {
      required: value => !!value || 'Requerido.',
      min: v => (v && v.length <= 10) || 'Minimo 8 caracteres'
    },
    google: null,
    map: null,
    model: 0,
    mapa: '',

  }),

  mounted(){
    console.log(this.$refs.mapajs)
    this.mapa = this.$refs.mapajs
    var coord = {lat: 25.9046208574684 ,lng: -100.16380256161108};
    var texto = `<h1>SOFSOLUTION</h1>` + `<p>
      Rey de toledo #130, Real de palmas 65760, Gral. Zuazua, N.L.
    </p>` + `<img src="https://sofsolution.com/imagenes/sofsol.png" style="height: 50px;"></img>
    <br/>
     <a href="https://www.google.com/maps/place/Sof+Solution/@25.90416,-100.1665812,17z/data=!3m1!4b1!4m5!3m4!1s0x8662f1965d1f777b:0x6ce0c673cefdeb53!8m2!3d25.9041552!4d-100.1643925" target="_blank">Ir a la ubicación</a>`

    this.map = new google.maps.Map(this.$refs.mapajs,{
      zoom: 14,
      center: coord,
      mapTypeControl: false,
    });

    var marker = new google.maps.Marker({
      position: coord,
      map: this.map,
      title: "SOFSOLUTION"
    });

    var info = new google.maps.InfoWindow({
      content: texto,
    });

    var info = new google.maps.InfoWindow({
      content: texto,
    });

    // marker.addListener('click', ()=>{
      info.open(this.map, marker)
    // })

    marker.addListener('click', ()=>{
      info.open(this.map, marker)
    })

  },

  created(){
    this.init()
    
  },

  methods:{

    init(){
      var me = this
      fetch('https://sofsolution.com:3000/likes',{ method: 'POST'}).then((response)=> {
        // The response is a Response instance.
        // You parse the data into a useable format using `.json()`
        return response.json();
      }).then((data)=> {
        console.log(data);  // { "userId": 1, "id": 1, "title": "...", "body": "..." }
        me.likes = data.numlikes
        document.getElementById("megusta").innerHTML = data[0].numlikes;
      });
    },

    validate () {
      this.$refs.form.validate()
      if(this.$refs.form.validate() == true){
        this.ingresar()
      }
    },

    enviarCorreo(){
      var payload = {
        nombre: this.nombre,
        correo: this.correo,
        telefono: this.telefono,
        empresa:  this.empresa,
        mensaje: this.mensaje
      }

      // console.log(payload)
      this.$http.post('correos.send', payload).then(response=>{
        this.color = "#C79700"
        this.text = "Se te enviará por correo y te contactaremos lo antes posible"
        this.snackbar = true
        this.nombre = ''
        this.correo  =''
        this.telefono = ''
        this.empresa = ''
        this.mensaje = ''
      }).catch(error=>{
        this.color = "#C79700"
        this.text = "Se te enviará por correo y te contactaremos lo antes posible"
        this.snackbar = true
        this.nombre = ''
        this.correo  =''
        this.telefono=''
        this.empresa = ''
        this.mensaje = ''
      })
    }
  }
};
</script>
<style>
        
  .wrapper-card {
    display: flex;
    flex-wrap: nowrap;
    margin: 40px auto;
    width: 77%;
  }
  
  .card {
    background: #fff;
    border-radius: 3px;
    box-shadow: 0 1px 1px transparent;
    flex: 1;
    margin: 8px;
    padding: 15px;
    position: relative;
    text-align: center;
    transition: all 0.5s ease-in-out;
  }
    
  .card.popular {
    margin-top: -10px;
    margin-bottom: -10px;
  }
    
  .card.popular .card-title h3 {
    color: #3498db;
    font-size: 22px;
  }
    
  .card.popular .card-price {
    margin: 20px;
  }
    
  .card.popular .card-price h1 {
    color: #3498db;
    font-size: 50px;
  }
    
  .card.popular .card-action button {
    background-color: #3498db;
    border-radius: 80px;
    color: #fff;
    font-size: 17px;
    margin-top: -15px;
    padding: 15px;
    height: 60px;
  }
    
  .card.popular .card-action button:hover {
    background-color: #2386c8;
    font-size: 23px;
  }
  
  .card:hover {
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  }
  
  .card-ribbon {
    position: absolute;
    overflow: hidden;
    top: -10px;
    left: -10px;
    width: 114px;
    height: 112px;
  }
    
  .card-ribbon span {
    position: absolute;
    display: block;
    width: 160px;
    padding: 10px 0;
    background-color: #3498db;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
    color: #fff;
    font-size: 13px;
    text-transform: uppercase;
    text-align: center;
    left: -35px;
    top: 25px;
    transform: rotate(-45deg);
  }
    
  .card-ribbon::before,
  .card-ribbon::after {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    border: 5px solid #2980b9;
    border-top-color: transparent;
    border-left-color: transparent;
  }
    
  .card-ribbon::before {
    top: 0;
    right: 0;
  }
  
  .card-ribbon::after {
    bottom: 0;
    left: 0;
  }
  
  .card-title h3 {
    color: rgba(0, 0, 0, 0.3);
    font-size: 15px;
    text-transform: uppercase;
  }
  
  .card-title h4 {
    color: rgba(0, 0, 0, 0.6);
  }
  
  .card-price {
    margin: 60px 0;
    margin-top: 20px;
  }
  
  .card-price h1 {
    font-size: 46px;
  }
  
  .card-price h1 sup {
    font-size: 15px;
    display: inline-block;
    margin-left: -20px;
    width: 10px;
  }
  
  .card-price h1 small {
    color: rgba(0, 0, 0, 0.3);
    display: block;
    font-size: 11px;
    text-transform: uppercase;
  }
  
  .card-description ul {
    display: block;
    list-style: none;
    margin: 60px 0;
    margin-top: 10px;
    padding: 0;
  }
  
  .card-description li {
    color: rgba(0, 0, 0, 0.6);
    font-size: 15px;
    margin: 0 0 15px;
    margin-top: 10px;
  }
  
  .card-description li::before {
    font-family: FontAwesome;
    content: "\f00c";
    padding: 0 5px 0 0;
    color: rgba(0, 0, 0, 0.15);
    margin-top: 10px;
  }
  
  .card-action button {
    background: transparent;
    border: 2px solid #3498db;
    border-radius: 30px;
    color: #3498db;
    cursor: pointer;
    display: block;
    font-size: 15px;
    font-weight: bold;
    padding: 20px;
    width: 100%;
    height: 60px;
    text-transform: uppercase;
    transition: all 0.3s ease-in-out;
  }
  
  .card-action button:hover {
    background-color: #3498db;
    box-shadow: 0 2px 4px #196090;
    color: #fff;
    font-size: 17px;
  }



/***************************************************************
***************************************************************
***************************************************************
***************************************************************/




.contenedor {
  width: 90%;
  max-width: 1280px;
  margin: auto;
}



.boton {
  background: #253547;
  color: #fff;
  text-decoration: none;
  padding: 15px 30px;
  border-radius: 5px;
  font-size: 14px;
  box-shadow: 0px 20px 30px 0px rgba(0,0,0,0.2);
  margin-top: 20px;
  display: inline-block;
}


#satisfaction {
}

#satisfaction h3 {
  font-weight: bold;
  font-size: 38px;
  text-align: center;
}

#satisfaction .contenedor-tarjetas {
  display: flex;
  justify-content: center;
}

#satisfaction .tarjeta {
  background-image: url(/imagenes/tarjeta1.png), linear-gradient(237deg, #050B1F 0%, #0752fe 100%);
  background-position: bottom left;
  background-size: cover;
  border-radius: 24px;
  width: 100%;
  margin: 0 ;
  padding: 27px 40px 26px 33px;
}

#satisfaction .tarjeta-dos {
  background-image: url(/imagenes/tarjeta2.png), linear-gradient(150deg, #D8A917 0%, #ffdc71 100%);
}

#satisfaction h4 {
  font-size: 26px;
  color: #fff;
  margin: 0;
  margin-bottom: 14px;
}

#satisfaction p {
  color: #fff;
  line-height: 34px;
  margin: 0;
}



@media screen and (max-width: 480px) {
  nav {
    position: absolute;
    top: 100%;
    left: 0;
    background: #232220;
    width: 100%;
    display: none;
  }

  nav ul li {
    display: block;
    margin: 23px 20px;
  }

  nav ul li a {
    color: #fff;
  }

  .menu-icon {
    display: block;
    position: absolute;
    right: 65px;
}

#services h3, #pricing h3, #pricing h3, #easy h3, #testimonials h3 {
    font-size: 24px;
}

}

</style>